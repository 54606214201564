import React, { FC } from 'react'
import { ContactComponent } from '../components'

interface Props {
  title: string
  description: string
  action?: string
}

const href = '/contatti'
const image = 'https://static1.carplanner.com/website/assets/maps.jpg'

const ContactContainer: FC<Props> = ({ title, description, action }) => (
  <ContactComponent
    title={title}
    description={description}
    action={action}
    href={action ? href : null}
    image={image}
  />
)

export default ContactContainer
